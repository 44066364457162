//const API_URL = 'https://tamolo.in/projects/bybv/csadmin/api/'
const API_URL = 'https://bybv.in/csadmin/api/'


const BASE_URL =   ""
const FRONT_URL =   "https://bybv.in/"    
const DEFAULT_IMAGE = "/img/defaultimage.jpg";

export default { API_URL,BASE_URL,FRONT_URL,DEFAULT_IMAGE };

